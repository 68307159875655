<template>
  <div>
    <div class="mt-3 flex items-center">
      <div class="container mx-auto">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div class="p-5 shadow-md">
            <form>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="block text-gray-700">IP address or domain
                  name</label>
                <input type="input" v-model="ipAddress" class="w-full p-2 bg-info bg-opacity-10 border border-primary">
              </div>
              <div class="mb-3">
                <button type="button" @click="toggleCustomPortInput"
                  class="flex items-center text-gray-700 cursor-pointer">
                  Custom port
                  <span class="ml-2">
                    <svg v-if="showCustomPortInput" class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m5 15 7-7 7 7" />
                    </svg>
                    <svg v-else class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m19 9-7 7-7-7" />
                    </svg>
                  </span>
                </button>
                <input v-if="showCustomPortInput" type="input" v-model="customPort"
                  class="w-full p-2 bg-info bg-opacity-10 border border-primary" id="customPortInput">
              </div>
              <div class="mb-3">
                <button type="button" :disabled="loading" class="bg-blue-500 text-white px-4 py-2 rounded"
                  @click="fetchPorts">
                  <span v-if="loading" class="flex items-center">
                    <svg class="animate-spin h-5 w-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="12" r="10" stroke-width="4" stroke-linecap="round"></circle>
                    </svg>
                  </span>
                  <span v-else>Scan</span>
                </button>
              </div>
            </form>
          </div>
          <div v-if="ports" class="relative overflow-x-auto">
            <table class="w-full text-sm text-left right text-gray-500 dark:text-gray-400 mt-4 item-center">
              <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
                <tr class="text-center">
                  <th scope="col" class="px-2 py-2 rounded-s-lg text-center">
                    Ports
                  </th>
                  <th scope="col" class="px-2 py-2 rounded-s-lg text-center">
                    Service
                  </th>
                  <th scope="col" class="px-2 py-2 rounded-e-lg">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="port in ports ? ports.openPorts.concat(ports.closedPorts) : []" :key="port"
                  class="dark:bg-gray-800">
                  <td class="px-4 py-2">
                    {{ port.port }}
                  </td>
                  <td class="px-4 py- 2">
                    {{ port.description }}
                  </td>
                  <td class="px-4 py-2">
                    <span v-if="ports && ports.openPorts.includes(port)" title="Open Port">
                      <svg class="w-4 h-4 text-blue-500 item-center" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 13.85L10.6 19.35L19 6" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </span>
                    <span v-else title="Closed Port">
                      <svg class="w-4 h-4 text-red-500" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="customPortStatus">
            <div v-if="customPortStatus.status === true"
              class="p-4 mb-4 text-green-800 bg-green-100 dark:bg-green-800 dark:text-green-300 rounded-md flex items-center"
              role="alert">
              <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13.85L10.6 19.35L19 6"></path>
              </svg>
              <span class="font-medium">Port {{ customPort }} is open</span>
            </div>
            <div v-if="customPortStatus.status === false"
              class="p-4 mb-4 text-red-800 bg-red-100 dark:bg-red-800 dark:text-red-300 rounded-md flex items-center"
              role="alert">
              <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6L18 18"></path>
              </svg>
              <span class="font-medium">Port {{ customPort }} is closed</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { ref } from 'vue';
import axios from 'axios';

const ipAddress = ref('');
const customPort = ref('');
const ports = ref(null);
const customPortStatus = ref(null);
const showCustomPortInput = ref(false);
const loading = ref(false);


const toggleCustomPortInput = () => {
  showCustomPortInput.value = !showCustomPortInput.value;
};

const fetchPorts = async () => {
  try {
    loading.value = true;
    ports.value = null;
    customPortStatus.value = null;
    const query = {
      ipAddress: ipAddress.value,
    };

    if (customPort.value) {
      query.port = customPort.value;
    }

    if (!customPort.value) {
      const responseDefault = await axios.get('https://servermonitorapi.krrishg.com/ports/default', { params: query });
      ports.value = responseDefault.data;
    } else {
      const responseCustom = await axios.get('https://servermonitorapi.krrishg.com/ports', { params: query });
      customPortStatus.value = responseCustom.data;
    }
  } catch (error) {
    console.error('Error fetching ports:', error);
  } finally {
    loading.value = false;
  }
};
</script>  