<template>
  <NavBar/>
  <ServerMonitor/>
</template>

<script>
import ServerMonitor from './components/ServerMonitor.vue';
import NavBar from './components/NavBar.vue'

export default {
  name: 'App',
  components: {
    ServerMonitor,
    NavBar
  }
}
</script>

<style>
body {
  background-color: aliceblue;
}
</style>
